import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from 'styled-components';
import "@babel/polyfill";

import Layout from "../layouts";
import Head from "../components/head";

import Headshot from '../images/headshot.png';

import '../css/single-faq.css';


class SingleLeadershipTemplate extends Component {
  render() {
    const leadership = this.props.data.wpLeadership;
    var image = Headshot;

    if(leadership.featuredImage) {
      image = leadership.featuredImage.node.localFile.publicURL;
    }

    return (
      <Layout>
        <div class="container">
          <div className="bioBox maincopy" style={{ maxWidth: '800px', margin: 'auto', padding: '40px' }}>
            {image && <img src={image} className="flex-img" />}

            <h3>{leadership.cptTeamMember.firstName} {leadership.cptTeamMember.lastName}</h3>
            <p style={{ marginBottom: leadership.cptTeamMember.moreInfo ? 0 : '15px' }}>
              {leadership.cptTeamMember.title}
            </p>
            {leadership.cptTeamMember.moreInfo && <p>{leadership.cptTeamMember.moreInfo}</p>}
            <div dangerouslySetInnerHTML={{ __html: leadership.content }}></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SingleLeadershipTemplate

SingleLeadershipTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpLeadership(id: {eq: $id}) {
      id
      title
      link
      content
      cptTeamMember {
        firstName
        lastName
        title
        moreInfo
      }
      teamRoles {
        nodes {
          id
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`